import React from "react"
import { Link } from "gatsby"
import SEO from "../../components/seo"

import Header from "../../components/en/header";
import Footer from "../../components/footer";

//Icon
import { BiArrowBack } from "react-icons/bi";

//Styles
import "./../../styles/privacity.scss";

const Accesibility = () => (
    <div>
        <Header checked={""} />
        <SEO
            title="Accesibility"
            description="Accesibility Declaration. In CIE we are compromised to make our site available for everyone."
        />
        <section className="privacity">
            <Link to="/" className="link" > <BiArrowBack size="18" className="icon" /> Home </Link>
            <h1>Accesibility</h1>
            <article>
                <p>We are compromised with accesibility, diversity and inclusion. We think everyone should be able to easily access to our web from any device with no problems. This site meets today's global standards in web development with high end structure and protocols. </p>
                <p>Some of the aspects we have considered are:</p>
                <ul role="list" >
                    <li role="listitem" >- Separation of content from presentation through the use of stylesheets.(CSS).</li>
                    <li role="listitem" >- Structure and correct content labelling.</li>
                    <li role="listitem" >- Use of tags like h1 (and consequents), ul and blockquote by use and semantic meaning and not as style and format tags.</li>
                    <li role="listitem" >- No tables are used for design and structure of the site.</li>
                    <li role="listitem" >- The entire site works with responsive designs to adapt correctly to any screen.</li>
                    <li role="listitem" >- Links have meaningful text.</li>
                    <li role="listitem" >- Colors and color palettes that allows a comfortable reading.</li>
                    <li role="listitem" >- Javascript is only for styling purposes.</li>
                    <li role="listitem" >- No iframes are used.</li>
                    <li role="listitem" >- Every image has an “alt” attribute.</li>
                    <li role="listitem" >- Compatibility with all browsers.</li>
                </ul>
                <p>Every page in the site uses the basic requirements about navigation and structure for legibility and use. Site's structure has titles and hierarchies for navigation and SEO improvements, following the design and web development guide. </p>
                <p>Either if you use assistive technologies like a screen reader, voice recognition software or touch assistants, we want our site to be completely accessible for you. So if you have any doubt or difficultie, please contact us at <a href="mailto:contact@cienegocios.com?subject=Accesibility">contact@cienegocios.com</a> and we'll do anything to help you. </p>
            </article>
        </section>
        <Footer lang="en" />
    </div>
)

export default Accesibility;
